<template>
  <div>
    <b-container>
      <img alt="Vue logo" src="../assets/logo.png" />
      <b-row align-h="center">
        <div v-if="!this.$store.state.token">
          <b-button variant="success" size="lg" @click="signIn"
              >Sign in with Cognito</b-button
            >
        </div>
        <div v-if="this.$store.state.token">
          <h4>
              Welcome, {{ this.$store.state.username }}({{
                this.$store.state.email
              }})
          </h4>
          <b-list-group>
            <b-list-group-item
                v-for="room in rooms"
                :key="room.id"
                :to="{ name: 'Room', params: { id: room.id } }"
              >
                {{ room.name }}
            </b-list-group-item>
          </b-list-group>
          <b-button variant="danger" size="lg" class="mt-2" @click="signOut"
              >Sign out</b-button
            >
        </div>
      </b-row>
    </b-container>
  </div>
</template>
  
  <script>
  import { Auth, Hub } from "aws-amplify"
  import axios from "axios"
  import config from "@/config"
  
  export default {
    name: "Home",
    data() {
      return {
        rooms: [],
      }
    },
    methods: {
      signIn: () => {
        console.log("Home signIn()")
        Auth.federatedSignIn()
      },
      signOut: () => {
        console.log("Home signOut()")
        Auth.signOut()
          .then((data) => {
            console.log(data)
          })
          .catch((err) => console.log(err))
      },
      getRooms() {
        axios({
          method: "GET",
          url: config.API_BASE_URL + "v1/rooms",
          headers: { Authorization: this.$store.state.token },
        })
          .then((response) => {
            console.log("API Response: " + JSON.stringify(response)),
              (this.rooms = response.data)
          })
          .catch((err) => {
            console.log(`Error Response: ${err}`)
          })
      },
    },
    mounted() {
      console.log("Home mounted()")
      Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {
          case "signIn":
            console.log("signIn data: " + JSON.stringify(data))
            this.$store.state.username = data.username
            this.$store.state.email = data.signInUserSession.idToken.payload.email
            this.$store.state.token = data.signInUserSession.idToken.jwtToken
            this.getRooms()
            break
          case "signOut":
            this.$store.state.username = null
            this.$store.state.email = null
            this.$store.state.token = null
            break
        }
      })
      Auth.currentAuthenticatedUser()
        .then((user) => {
          console.log("AuthenticatedUserName: " + user.username)
          console.log("Refreshing access and id tokens")
          Auth.currentSession()
            .then((data) => console.log(data))
            .catch((err) => console.log("Error refreshing tokens: " + err))
          this.getRooms()
        })
        .catch((err) => console.log("Auth.currentAuthenticatedUser(): " + err))
    },
  }
  </script>
  
  <style scoped></style>