import Vue from "vue"
import VueRouter from "vue-router"
import Home from "@/views/Home.vue"
import store from "@/store/index.js"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/room",
    name: "Room",
    component: () => import(/* webpackChunkName: "room" */ "@/views/Room.vue"),
    props: true,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.token) {
    next({ path: "/", query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

export default router