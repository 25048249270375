export default {
    REGION: "ap-northeast-1",
    USER_POOL_ID: "ap-northeast-1_z1qFSAykG",
    USER_POOL_WEB_CLIENT_ID: "5nbghjqf18hfhgeakncfh5616p",
    OAUTH_DOMAIN: "adfs-hokazono.auth.ap-northeast-1.amazoncognito.com",
    OAUTH_REDIRECT_SIGN_IN: "https://webapp-hokazono.dilabs21.com/",
    OAUTH_REDIRECT_SIGN_OUT: "https://webapp-hokazono.dilabs21.com/",
    API_BASE_URL: "https://webapp-hokazono.dilabs21.com/api/",
  };





