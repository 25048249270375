import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
      username: "",
      email: "",
      token: "",
  },
  mutations: {
      updateUser(state, user) {
          state.username = user.username
          state.email = user.email
          state.token = user.token
      }
  },
  actions: {
      auth(context, user) {
          context.commit("updateUser", user)
      }
  },
  modules: {},
})

export default store
