import Vue from "vue"
import App from "@/App.vue"
import config from "@/config"
import router from "@/router"
import store from "@/store"
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader"
import { Amplify } from "aws-amplify"
import { BootstrapVue } from "bootstrap-vue"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"


Vue.use(BootstrapVue)

Vue.config.productionTip = false

Amplify.configure({
  Auth: {
    region: config.REGION,
    userPoolId: config.USER_POOL_ID,
    userPoolWebClientId: config.USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: config.OAUTH_DOMAIN,
      scope: ["email", "openid"],
      redirectSignIn: config.OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: config.OAUTH_REDIRECT_SIGN_OUT,
      responseType: "token",
    },
  },
})

applyPolyfills().then(() => {
  defineCustomElements(window)
})

Vue.config.ignoredElements = [/amplify-\w*/]

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app")